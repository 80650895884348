'use client'

import { type PropsWithChildren, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import OAuthProvider from 'lib/oauth/Provider'
import AnalyticsProvider from 'lib/analytics/AnalyticsProvider'

function ClientProviders({ children }: Readonly<PropsWithChildren>) {
  const [queryClient] = useState(() => new QueryClient())

  return (
    <AnalyticsProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <OAuthProvider>
          {children}
        </OAuthProvider>
      </QueryClientProvider>
    </AnalyticsProvider>
  )
}

export default ClientProviders
